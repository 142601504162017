import React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useGetDashboardQuery } from '../../api/admin/statistics';

export function AdminIndexPage() {
  const getDashboardQuery = useGetDashboardQuery();

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Välkommen till Chassikollens adminpanel
      </Typography>
      <Typography variant="body1" gutterBottom>
        Här kan du hantera användare, registrerade objekt och ägare.
      </Typography>
      {getDashboardQuery.data && (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Användare" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.users}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Objekt" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.objects}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Ägare" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.owners}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Finansieringar" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.financings}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Leasingar" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.leasings}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Försäkringar" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.insurances}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title="Produkter" />
              <CardContent>
                <Typography>Totalt: {getDashboardQuery.data.products}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {getDashboardQuery.isLoading && <Typography>Laddar...</Typography>}
      {getDashboardQuery.isError && <Typography>Ett fel uppstod</Typography>}
    </Box>
  );
}
