import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';

const productKeys = {
  all: ['products'],
  list: (filter: string) => [...productKeys.all, 'list', filter],
  details: (id: number) => [...productKeys.all, 'details', id],
};

export type ProductListItemDto = {
  id: number;
  name: string;
  price: number;
};

export type ProductDto = {
  id: number;
  name: string;
  description: string | null;
  price: number;
  imageFileName: string | null;
};

export const useProductsQuery = (filter: string) =>
  useQuery({
    queryKey: productKeys.list(filter),
    queryFn: async () => {
      const response = await apiRequest2<ProductListItemDto[]>({
        action: `Admin/Products`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
        },
      });

      return response.data;
    },
  });

export const useProductQuery = (id: number | null) =>
  useQuery({
    enabled: id !== null,
    queryKey: productKeys.details(id ?? 0),
    queryFn: async () => {
      const response = await apiRequest2<ProductDto>({
        action: `Admin/Products/${id}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
  });

interface AddProductDto {
  name: string;
  description: string | null;
  price: number;
}

export const useAddProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (addProductDto: AddProductDto) =>
      apiRequest2<void>({
        action: `Admin/Products`,
        method: 'POST',
        allowAnonymous: false,
        body: JSON.stringify(addProductDto),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productKeys.all });
    },
  });
};

interface updateProductDto {
  id: number;
  name: string;
  description: string | null;
  price: number;
}

export const useUpdateProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateProductDto: updateProductDto) =>
      apiRequest2<void>({
        action: `Admin/Products/${updateProductDto.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify({
          name: updateProductDto.name,
          description: updateProductDto.description,
          price: updateProductDto.price,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productKeys.all });
    },
  });
};

export const useDeleteProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiRequest2<void>({
        action: `Admin/Products/${id}`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productKeys.all });
    },
  });
};

interface UploadProductImageDto {
  id: number;
  file: File;
}

export const useUploadProductImageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, file }: UploadProductImageDto) => {
      const formData = new FormData();
      formData.append('file', file);

      return apiRequest2<void>({
        action: `Admin/Products/${id}/Image`,
        method: 'POST',
        allowAnonymous: false,
        body: formData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productKeys.all });
    },
  });
};

export const useDeleteProductImageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiRequest2<void>({
        action: `Admin/Products/${id}/Image`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productKeys.all });
    },
  });
};
