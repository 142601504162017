import styled from '@emotion/styled';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { paths } from '../../navigation/paths';
import { useUser } from '../../context/UserProvider';
import { Roles } from '../../auth/roles';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import CategoryIcon from '@mui/icons-material/Category';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PeopleIcon from '@mui/icons-material/People';
// import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const AdminPageWrapper = styled.div``;

const AdminContent = styled.div`
  padding: 10px;

  @media (min-width: 600px) {
    flex: 1;
    padding: 20px;
    margin-left: 210px;
  }
`;

const AdminMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  a {
    color: #000;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 5px;
  }

  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    display: block;
    width: 200px;
    background-color: #fff;
    border-right: 1px solid #eee;
    background-color: rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 60px;
    padding-top: 10px;
    height: calc(100vh - 60px);

    a {
      font-size: 1rem;
      padding: 6px 16px;
    }
  }
`;

const AdminMenu = () => {
  return (
    <AdminMenuWrapper>
      <MenuItem component={Link} to={paths.admin.path}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText>Översikt</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={paths.editFinancions.path}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText>Finansieringar</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={paths.editRegistrations.path}>
        <ListItemIcon>
          <AppRegistrationIcon />
        </ListItemIcon>
        <ListItemText>Registreringar</ListItemText>
      </MenuItem>

      <MenuItem component={Link} to={paths.editDataAttributes.path}>
        <ListItemIcon>
          <EditAttributesIcon />
        </ListItemIcon>
        <ListItemText>Attribut</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={paths.editCategories.path}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText>Kategorier</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={paths.editOwners.path}>
        <ListItemIcon>
          <CardMembershipIcon />
        </ListItemIcon>
        <ListItemText>Ägare</ListItemText>
      </MenuItem>
      <MenuItem component={Link} to={paths.editUsers.path}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText>Användare</ListItemText>
      </MenuItem>
      {/* <MenuItem component={Link} to={paths.fortnox.path}>
        <ListItemIcon>
          <LocalLibraryIcon />
        </ListItemIcon>
        <ListItemText>Fortnox</ListItemText>
      </MenuItem> */}
      <MenuItem component={Link} to={paths.editProducts.path}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText>Produkter</ListItemText>
      </MenuItem>
    </AdminMenuWrapper>
  );
};

export const AdminPage = () => {
  const { isAuthenticated, userProfile } = useUser();

  const isAdmin = (isAuthenticated && userProfile?.roles.includes(Roles.Admin)) ?? false;

  if (!isAdmin) {
    return <Navigate to={paths.home.path} replace />;
  }

  return (
    <AdminPageWrapper>
      <AdminMenu />
      <AdminContent>
        <Outlet />
      </AdminContent>
    </AdminPageWrapper>
  );
};
