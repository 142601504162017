import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { DefaultButton, DefaultTextButton } from '../../../layout';

type ProductDialogParams = {
  open: boolean;
  title: string;
  initialValues: { id: number; name: string; description: string | null; price: number };
  onSave: (id: number, name: string, description: string | null, price: number) => void;
  onClose: () => void;
};

export const ProductDialog = ({ open, title, initialValues, onSave, onClose }: ProductDialogParams) => {
  const [name, setName] = useState<string>(initialValues.name);
  const [description, setDescription] = useState<string>(initialValues.description ?? '');
  const [price, setPrice] = useState<number>(initialValues.price);

  function IsValid() {
    return name && price !== null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <FormControl>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField label="Namn" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="normal" />
          <TextField
            label="Beskrivning"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            label="Pris"
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton
            disabled={!IsValid()}
            onClick={() => {
              if (initialValues !== null && name && price !== null) {
                onSave(initialValues.id, name, description, price);
              }
              onClose();
            }}
          >
            Spara
          </DefaultButton>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
};
