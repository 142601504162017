import { useQuery } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';

const statisticsKeys = {
  all: ['statistics'],
  dashboard: () => [...statisticsKeys.all, 'dashboard'],
};

export const useGetDashboardQuery = () =>
  useQuery({
    queryKey: statisticsKeys.dashboard(),
    queryFn: async () => {
      const response = await apiRequest2<GetDashboardResponse>({
        action: `Admin/Statistics/GetDashboard`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    staleTime: 1000 * 60 * 60,
  });

export type GetDashboardResponse = {
  users: number;
  objects: number;
  owners: number;
  financings: number;
  leasings: number;
  insurances: number;
  products: number;
};
